define("ember-cli-meta-tags/components/head-tag", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (element @headTag.type) as |Tag|}}
    {{! template-lint-disable block-indentation }}
    <Tag
      href={{@headTag.attrs.href}}
      target={{@headTag.attrs.target}}
      charset={{@headTag.attrs.charset}}
      crossorigin={{@headTag.attrs.crossorigin}}
      dir={{@headTag.attrs.dir}}
      hreflang={{@headTag.attrs.hreflang}}
      media={{@headTag.attrs.media}}
      rel={{@headTag.attrs.rel}}
      rev={{@headTag.attrs.rev}}
      sizes={{@headTag.attrs.sizes}}
      type={{@headTag.attrs.type}}
      content={{@headTag.attrs.content}}
      http-equiv={{@headTag.attrs.http-equiv}}
      name={{@headTag.attrs.name}}
      scheme={{@headTag.attrs.scheme}}
      async={{@headTag.attrs.async}}
      defer={{@headTag.attrs.defer}}
      src={{@headTag.attrs.src}}
      property={{@headTag.attrs.property}}
      itemprop={{@headTag.attrs.itemprop}}
      id={{@headTag.attrs.id}}
    >{{@headTag.content}}</Tag>
  {{/let}}
  */
  {
    "id": "D3iPZfNN",
    "block": "[[[44,[[28,[37,1],[[30,1,[\"type\"]]],null]],[[[1,\"  \"],[8,[30,2],[[16,6,[30,1,[\"attrs\",\"href\"]]],[16,\"target\",[30,1,[\"attrs\",\"target\"]]],[16,\"charset\",[30,1,[\"attrs\",\"charset\"]]],[16,\"crossorigin\",[30,1,[\"attrs\",\"crossorigin\"]]],[16,\"dir\",[30,1,[\"attrs\",\"dir\"]]],[16,\"hreflang\",[30,1,[\"attrs\",\"hreflang\"]]],[16,\"media\",[30,1,[\"attrs\",\"media\"]]],[16,\"rel\",[30,1,[\"attrs\",\"rel\"]]],[16,\"rev\",[30,1,[\"attrs\",\"rev\"]]],[16,\"sizes\",[30,1,[\"attrs\",\"sizes\"]]],[16,\"content\",[30,1,[\"attrs\",\"content\"]]],[16,\"http-equiv\",[30,1,[\"attrs\",\"http-equiv\"]]],[16,3,[30,1,[\"attrs\",\"name\"]]],[16,\"scheme\",[30,1,[\"attrs\",\"scheme\"]]],[16,\"async\",[30,1,[\"attrs\",\"async\"]]],[16,\"defer\",[30,1,[\"attrs\",\"defer\"]]],[16,\"src\",[30,1,[\"attrs\",\"src\"]]],[16,\"property\",[30,1,[\"attrs\",\"property\"]]],[16,\"itemprop\",[30,1,[\"attrs\",\"itemprop\"]]],[16,1,[30,1,[\"attrs\",\"id\"]]],[16,4,[30,1,[\"attrs\",\"type\"]]]],null,[[\"default\"],[[[[1,[30,1,[\"content\"]]]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@headTag\",\"Tag\"],false,[\"let\",\"element\"]]",
    "moduleName": "ember-cli-meta-tags/components/head-tag.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});