define("ember-svg-jar/inlined/arrow_right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 34V14l10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "48",
      "width": "48",
      "viewBox": "12 12 24 24"
    }
  };
});