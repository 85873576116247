define("ember-svg-jar/inlined/ru", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_503_2726)\"><rect x=\".25\" y=\".25\" width=\"27.5\" height=\"19.5\" rx=\"1.75\" fill=\"#fff\" stroke=\"#F5F5F5\" stroke-width=\".5\"/><mask id=\"a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"28\" height=\"20\"><rect x=\".25\" y=\".25\" width=\"27.5\" height=\"19.5\" rx=\"1.75\" fill=\"#fff\" stroke=\"#fff\" stroke-width=\".5\"/></mask><g mask=\"url(#a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M0 13.333h28V6.667H0v6.666z\" fill=\"#0C47B7\"/><path d=\"M0 20h28v-6.667H0V20z\" fill=\"#E53B35\"/></g></g><defs><clipPath id=\"clip0_503_2726\"><rect width=\"28\" height=\"20\" rx=\"2\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "800",
      "height": "800",
      "viewBox": "0 -4 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});