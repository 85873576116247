define("ember-svg-jar/inlined/keyboard_arrow_left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.05 36L16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "48",
      "width": "48"
    }
  };
});