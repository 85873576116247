define("ember-svg-jar/inlined/keyboard_arrow_right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.75 36l-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "48",
      "width": "48"
    }
  };
});