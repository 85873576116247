define("ember-cli-meta-tags/initializers/router-head-tags", ["exports", "@ember/routing/router", "@ember/service"], function (_exports, _router, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _router.default.reopen({
      headTags: (0, _service.inject)(),
      init() {
        this._super(...arguments);
        this.headTags.router = this;
        this.on('routeDidChange', () => {
          this.headTags.collectHeadTags();
        });
      }
    });
  }
  var _default = _exports.default = {
    initialize
  };
});