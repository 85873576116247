define("ember-toggle/components/x-toggle-label", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @show}}
    <label
      for={{@switchId}}
      class="toggle-text toggle-prefix {{this.type}}-label"
      ...attributes
      {{on 'click' this.clickLabel}}
    >
      {{#if (has-block)}}
        {{yield @label}}
      {{else}}
        {{@label}}
      {{/if}}
    </label>
  {{/if}}
  */
  {
    "id": "vm2lPEHB",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"label\"],[16,\"for\",[30,2]],[16,0,[29,[\"toggle-text toggle-prefix \",[30,0,[\"type\"]],\"-label\"]]],[17,3],[4,[38,2],[\"click\",[30,0,[\"clickLabel\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,[[30,4]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@show\",\"@switchId\",\"&attrs\",\"@label\",\"&default\"],false,[\"if\",\"label\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-toggle/components/x-toggle-label.hbs",
    "isStrictMode": false
  });
  let XToggleLabel = _exports.default = (_class = class XToggleLabel extends _component2.default {
    get type() {
      return this.args.value ? 'on' : 'off';
    }
    clickLabel(e) {
      e.stopPropagation();
      e.preventDefault();
      this.args.sendToggle(this.args.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clickLabel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickLabel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, XToggleLabel);
});